<template>
  <div>
   <Create/>
  </div>
</template>

<script>
import Create from '@/components/Create.vue';

export default {
  name: 'AdminDashboard',
  components: {
      Create,
  }
}
</script>

<style scoped>

</style>
